(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

/**
 * jQuery initialization.
 */
jQuery(function ($) {
  /**
   * On document load.
   */
  $(document).ready(function () {
    modalsInit();
    countersInit();
    dropdownsInit();
    validationsInit();
    priceSliderInit();
    slickSlidersInit();
    $('input[type="checkbox"]').on('change', function (event) {
      var isChecked = event.target.checked;

      if (isChecked) {
        $(event.target).closest('.checkbox').addClass('active');
      } else {
        $(event.target).closest('.checkbox').removeClass('active');
      }
    });
    $('.catalog__filters > .button').on('click', function () {
      $('.catalog__filters > .button').toggleClass('button--disabled');
      $('.catalog__filters-inner').toggleClass('active');
    });
    $('.input__toggle').on('click', function (event) {
      var $input = $(event.target).closest('.input').find('input');
      var inputType = $input.attr('type');
      $input.attr('type', inputType == 'password' ? 'text' : 'password');
    });
  });
});
/**
 * Price slider initialization.
 */

var priceSliderInit = function priceSliderInit() {
  var parseValues = function parseValues(values) {
    var _values = _slicedToArray(values, 2),
        startValue = _values[0],
        endValue = _values[1];

    $('#price-slider-start').val(startValue);
    $('#price-slider-end').val(endValue);
  };

  $('#price-slider').slider({
    min: 1,
    max: 200000,
    range: true,
    animate: 'fast',
    values: [50000, 100000],
    create: function create(event, ui) {
      parseValues([50000, 100000]);
    },
    slide: function slide(event, ui) {
      parseValues(ui.values);
    }
  }); // TODO: Refactor in one function

  $('#price-slider-start').on('change', function (event) {
    var value = $(event.target).val();

    if (value == Number(value)) {
      var _$$slider = $('#price-slider').slider('values'),
          _$$slider2 = _slicedToArray(_$$slider, 2),
          startValue = _$$slider2[0],
          endValue = _$$slider2[1];

      $('#price-slider').slider('values', [Number(value), endValue]);
    }
  }); // TODO: Refactor in one function

  $('#price-slider-end').on('change', function (event) {
    var value = $(event.target).val();

    if (value == Number(value)) {
      var _$$slider3 = $('#price-slider').slider('values'),
          _$$slider4 = _slicedToArray(_$$slider3, 2),
          startValue = _$$slider4[0],
          endValue = _$$slider4[1];

      $('#price-slider').slider('values', [startValue, Number(value)]);
    }
  });
};
/**
 * Slick sliders initialization.
 */


var slickSlidersInit = function slickSlidersInit() {
  var deviceWidth = $('body').innerWidth();
  var defaultSettings = {
    autoplay: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 5000
  };
  $('.section--intro .intro .slider .slider__items').slick(_objectSpread(_objectSpread({}, defaultSettings), {}, {
    prevArrow: $('.section--intro .intro .slider .slider__arrows .slider__arrow-prev'),
    nextArrow: $('.section--intro .intro .slider .slider__arrows .slider__arrow-next')
  }));
  $('.section--team .team .slider .slider__items').slick(_objectSpread(_objectSpread({}, defaultSettings), {}, {
    slidesToShow: 3,
    prevArrow: $('.section--team .team .slider .slider__arrows .slider__arrow-prev'),
    nextArrow: $('.section--team .team .slider .slider__arrows .slider__arrow-next'),
    responsive: [{
      breakpoint: 992,
      settings: {
        slidesToShow: 2
      }
    }, {
      breakpoint: 576,
      settings: {
        slidesToShow: 1
      }
    }]
  }));
  $('.section--examples .examples .slider .slider__items').slick(_objectSpread(_objectSpread({}, defaultSettings), {}, {
    slidesToShow: 2,
    prevArrow: $('.section--examples .examples .slider .slider__arrows .slider__arrow-prev'),
    nextArrow: $('.section--examples .examples .slider .slider__arrows .slider__arrow-next'),
    responsive: [{
      breakpoint: 576,
      settings: {
        slidesToShow: 1
      }
    }]
  }));
  $('.section--slider .slider--popular-products .slider__items').slick(_objectSpread(_objectSpread({}, defaultSettings), {}, {
    slidesToShow: 2,
    prevArrow: $('.section--slider .slider--popular-products .slider__arrows .slider__arrow-prev'),
    nextArrow: $('.section--slider .slider--popular-products .slider__arrows .slider__arrow-next'),
    responsive: [{
      breakpoint: 1200,
      settings: {
        slidesToShow: 1
      }
    }]
  }));

  if (deviceWidth <= 992) {
    $('.section--categories .grid').slick(_objectSpread(_objectSpread({}, defaultSettings), {}, {
      slidesToShow: 2,
      prevArrow: $('.section--categories .slider__arrows .slider__arrow-prev'),
      nextArrow: $('.section--categories .slider__arrows .slider__arrow-next'),
      responsive: [{
        breakpoint: 576,
        settings: {
          slidesToShow: 1
        }
      }]
    }));
    $('.section--popular-products .grid').slick(_objectSpread(_objectSpread({}, defaultSettings), {}, {
      slidesToShow: 2,
      prevArrow: $('.section--popular-products .slider__arrows .slider__arrow-prev'),
      nextArrow: $('.section--popular-products .slider__arrows .slider__arrow-next'),
      responsive: [{
        breakpoint: 576,
        settings: {
          slidesToShow: 1
        }
      }]
    }));
    $('.section--news .grid').slick(_objectSpread(_objectSpread({}, defaultSettings), {}, {
      slidesToShow: 2,
      prevArrow: $('.section--news .slider__arrows .slider__arrow-prev'),
      nextArrow: $('.section--news .slider__arrows .slider__arrow-next'),
      responsive: [{
        breakpoint: 576,
        settings: {
          slidesToShow: 1
        }
      }]
    }));
  }
};
/**
 * Dropdowns initialization.
 */


var dropdownsInit = function dropdownsInit() {
  $('.header .header__category').click(function (event) {
    var target = event.target.closest('.header__category');

    if (target) {
      $(target).siblings().removeClass('active');
      $(target).toggleClass('active');
      updateDropdownShow();
    }

    $(document).on('click', function (event) {
      var isInArea = !!event.target.closest('.header__categories');

      if (!isInArea) {
        $('.header .header__category').removeClass('active');
        $('.header .header__categories').removeClass('active');
      }
    });
  });
  /**
   * Dropdown show status update
   */

  var updateDropdownShow = function updateDropdownShow() {
    var show = !!$('.header .header__category.active').length;
    if (show) $('.header .header__categories').addClass('active');else $('.header .header__categories').removeClass('active');
  };
};
/**
 * Modals initialization.
 */


var modalsInit = function modalsInit() {
  /**
   * Modal trigger.
   */
  $('[data-modal]').on('click', function (event) {
    var modal = null;
    closeModal();

    if ($(event.target).closest('[data-modal]')) {
      modal = $(event.target).closest('[data-modal]').attr('data-modal');
    } else {
      modal = $(event.target).attr('data-modal');
    }

    if (modal) openModal(modal);
  });
  /**
   * Modals overlay trigger.
   */

  $('.modals .modals__inner').on('click', function (event) {
    if ($(event.target).hasClass('modals__inner')) {
      closeModal();
      setTimeout(closeModals, 100);
    }
  });
  /**
   * Modal close trigger.
   */

  $('.modals .modal .modal__close').on('click', function (event) {
    closeModal();
    setTimeout(closeModals, 100);
  });
};
/**
 * Modals window open action.
 */


var openModals = function openModals() {
  $('body').addClass('disabled');
  $('.modals').addClass('show');
  setTimeout(function () {
    $('.modals').addClass('active');
  }, 1);
};
/**
 * Modals window close action.
 */


var closeModals = function closeModals() {
  $('.modals').removeClass('active');
  setTimeout(function () {
    $('.modals').removeClass('show');
    $('body').removeClass('disabled');
  }, 300);
};
/**
 * Modal open action.
 * 
 * @param {string} modal 
 */


var openModal = function openModal(modal) {
  setTimeout(openModals, 1);
  setTimeout(function () {
    $(".modals .modal--".concat(modal)).addClass('active');
  }, 100);
};
/**
 * Modal close action.
 */


var closeModal = function closeModal() {
  $('.modals .modals__inner').find('.modal.active').removeClass('active');
};
/**
 * Validations initialization.
 */


var validationsInit = function validationsInit() {
  $.extend($.validator.messages, {
    required: 'Обязательно к заполнению',
    email: 'Пожалуйста, укажите верные данные',
    number: 'Пожалуйста, укажите верные данные',
    digits: 'Пожалуйста, укажите верные данные'
  });
  $.validator.addMethod("regex", function (value, element, regexp) {
    var re = new RegExp(regexp);
    return this.optional(element) || re.test(value);
  }, "Пожалуйста, укажите верные данные");
  $('.form--request').validate({
    rules: {
      name: {
        normalizer: function normalizer(value) {
          return value.trim();
        }
      },
      phone: {
        required: true,
        regex: "([0-9]{10})|(([0-9]{3})s+[0-9]{3}-[0-9]{4})"
      }
    }
  });
  $('.form--request-modal').validate({
    rules: {
      modal_name: {
        normalizer: function normalizer(value) {
          return value.trim();
        }
      },
      modal_phone: {
        required: true,
        regex: "([0-9]{10})|(([0-9]{3})s+[0-9]{3}-[0-9]{4})"
      }
    }
  });
  $('.form--review').validate({
    rules: {
      advantages: {
        required: true,
        normalizer: function normalizer(value) {
          return value.trim();
        }
      },
      disadvantages: {
        required: true,
        normalizer: function normalizer(value) {
          return value.trim();
        }
      },
      comment: {
        required: true,
        normalizer: function normalizer(value) {
          return value.trim();
        }
      }
    }
  });
};
/**
 * Counters initialization.
 */


var countersInit = function countersInit() {
  /**
   * Counter controls sync. 
   */
  var syncControls = function syncControls($count, $increment, $decrement, min, max) {
    var currentCount = +$count.innerText;
    if (currentCount === min) $($decrement).addClass('disabled');else $($decrement).removeClass('disabled');
    if (currentCount === max) $($increment).addClass('disabled');else $($increment).removeClass('disabled');
  };

  $('.counter').each(function (i, elem) {
    var elemCount = $($(elem).find('.counter__count')[0]);
    var elemDecrement = $(elem).find('.counter__control.decrement')[0];
    var elemIncrement = $(elem).find('.counter__control.increment')[0];
    var min = +$(elem).attr('data-counter-min');
    var max = +$(elem).attr('data-counter-max');
    syncControls(elemCount, elemIncrement, elemDecrement, min, max);
    $(elemDecrement).on('click', function () {
      var currentCount = +elemCount.val();
      if (currentCount === min) return;
      elemCount.val(currentCount - 1);
      syncControls(elemCount, elemIncrement, elemDecrement, min, max);
    });
    $(elemIncrement).on('click', function () {
      var currentCount = +elemCount.val();
      if (currentCount === max) return;
      elemCount.val(currentCount + 1);
      syncControls(elemCount, elemIncrement, elemDecrement, min, max);
    });
  });
};

},{}]},{},[1]);
